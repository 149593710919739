import {
    action,
} from 'mobx';
import {BuildModel}             from './buildModel';
import {
  getFirestore,
  onSnapshot,
  collection,
  doc,
  query,
  orderBy,
  limit,
  where,
  getDoc,
  Unsubscribe
} from 'firebase/firestore';
import {compareBuildsById} from "../utils";


export class BuildsStore {

   public getBuild = action(async (projectId: string, buildId?: string): Promise<BuildModel[]> => {

    const docRef = doc(getFirestore(), `features/builds/projects/${projectId}/builds/${buildId}`)
    const docSnapshot = await getDoc(docRef);

    return docSnapshot.exists() === true
      ? [new BuildModel(docSnapshot)]
      : []
  })

  public getBuildsAndListenForChanges = action((projectId: string, buildsLimit: number, buildsType: string, buildsByUsername: string, onBuildsReceived: (builds: BuildModel[]) => void): Unsubscribe => {

    let q;

    if(buildsByUsername)
      q = query(collection(getFirestore(), `features/builds/projects/${projectId}/builds`), where("triggeredByUsername", "==", buildsByUsername), limit(buildsLimit))

    else {

      if (buildsType === "all")
        q = query(collection(getFirestore(), `features/builds/projects/${projectId}/builds`), orderBy("createdAt", "desc"), limit(buildsLimit))

      else
        q = query(collection(getFirestore(), `features/builds/projects/${projectId}/builds`), where("type", "==", `'${buildsType}'`), orderBy("createdAt", "desc"), limit(buildsLimit));
    }

    const builds : BuildModel[] = [];

    return onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach(docChange => {
          if (docChange.type === 'added') {
            const build = new BuildModel(docChange.doc)
            if(!builds.find(b => b.id === build.id))
              builds.push(build)
          }
          else if (docChange.type === 'modified')
            console.log(`build changed and we saw it at the builds level  ${JSON.stringify(docChange.doc.data())}`);
        });
        builds.sort(compareBuildsById)
        onBuildsReceived(builds);
      },
      e => {
        console.error(`Error in Builds snapshot listener: ${e}`)
      });
  })
}
