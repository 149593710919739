import {useEffect, useState}    from 'react';
import {useParams}              from 'react-router-dom';
import {NotificationType,
        usePublishNotification} from '@yakoffice/notification-handler';
import {BuildsStore}            from '../models/buildsStore';
import {BuildModel}             from '../models/buildModel';


interface buildsControllerResult{
    isLoaded    : boolean;
    builds      : BuildModel[] | null
}

interface PropsType {
    buildId?    : string;
    buildsLimit : number;
    buildsType  : string;
    buildsByUsername: string;
}


export const useBuilds = (props: PropsType) => {

    const publishNotification = usePublishNotification()
    const {projectId}         = useParams<{projectId: string}>();

    const [result, setResult] = useState<buildsControllerResult>(() => ({
        isLoaded    : false,
        builds      : []}));

    useEffect(() => {

      let stopListening = null;
      const buildsStore = new BuildsStore();

      //FOR SEARCH ON BUILD ID
      if (props.buildId && props.buildId !== "") {
        buildsStore.getBuild(projectId, props.buildId)
          .then(builds => {
            setResult({isLoaded: true, builds: builds});
          })
          .catch(e => {
            console.log(e);
            publishNotification({
              title: "Error Loading Build",
              notificationType: NotificationType.Error,
              message: e.toString()
            });
            setResult({isLoaded: true, builds: []})
          })
      }

      //FOR ALL BUILDS
      else {
        try {
          stopListening = buildsStore.getBuildsAndListenForChanges(
            projectId,
            props.buildsLimit,
            props.buildsType,
            props.buildsByUsername,
            (builds) => setResult({isLoaded: true,builds: builds}))
        }catch(e){
          console.log(e);
          publishNotification({
            title: "Error Loading Builds",
            notificationType: NotificationType.Error,
            message: e.toString()
          });
          setResult({isLoaded: false, builds: []})
        }
      }
      return () => stopListening && stopListening()
    }, [projectId, publishNotification, props.buildsLimit, props.buildsType, props.buildId, props.buildsByUsername]);

    return result;
};
